import useSWR, {KeyedMutator} from 'swr'

import { useCompany } from './useCompany'
import { ConnectionType } from 'types/company.types'
import type {IPlaidLinkToken, PlaidLinkTokenUpdateParams} from 'types/user.types'
import {getErrorMsg} from "../utils/geterror";
import {useCallback, useState} from "react";
import {api} from "../utils/axios";
import {PlaidBankFeed, PlaidConnectionResponse} from "../types/plaid.type";
import {BankFeedResponse} from "../types/bank-feed.types";
import {App} from "antd";




interface UsePlaidConnection {
  loading: boolean;
  onConnect: (token: string, companyId: number, isUpdate: boolean) => Promise<void>;
  onDelete: (connection: PlaidConnectionResponse, companyId: number) => Promise<void>;
}

export function usePlaidLinkToken() {
  const { data, error, isLoading } = useSWR<IPlaidLinkToken>('connection/plaid', { revalidateOnFocus: false })

  return { token: data, error, isLoading }
}


export function usePlaidLinkTokenUpdate(params?: PlaidLinkTokenUpdateParams) {
  const { data, error, isLoading } = useSWR<IPlaidLinkToken>(
      params ? `connection/plaid/manage?companyId=${params.companyId}&bankFeedId=${params.bankFeedId}` : null,
      {
        revalidateOnFocus: false
      }
  )

  return {
    token: data,
    error,
    isLoading
  }
}

export function usePlaidAccounts() {
  const { company } = useCompany()

  const connections = company?.connections

  const plaid = connections?.find((c) => c.type.id === ConnectionType.Plaid)


  return { plaid }
}

export interface UsePlaidBankFeedsReturn {
  bankFeeds: PlaidBankFeed[]
  isLoading: boolean
  error: Error | null
  mutate: KeyedMutator<PlaidBankFeed[]>
  refresh: (feedId: string, refreshWithBank?: boolean) => Promise<void>
}

export function usePlaidBankFeeds(companyId?: number): UsePlaidBankFeedsReturn {
  const {notification } = App.useApp();
  const {
    data: bankFeeds,
    error,
    isLoading,
    mutate
  } = useSWR<PlaidBankFeed[]>(
      companyId ? `/connection/bankfeed?companyId=${companyId}` : null,
      async (url:string) => {
        try {
          const response = await api.get(url)
          return response.data
        } catch (err) {
          notification.error({
            message: 'Error fetching bank feeds',
            description: getErrorMsg(err).description
          })
          throw err
        }
      }
  )

  const refresh = useCallback(async (feedId: string, refreshWithBank: boolean = false) => {
    if (!companyId) return

    try {
      await api.post('/connection/bankfeed/refresh', {
        companyId,
        feedId,
        refreshWithBank
      })

      notification.success({
        message: 'Refresh initiated',
        description: 'Bank feed refresh has been initiated. This may take a few minutes.'
      })

      // Mutate the data without void operator
      await mutate()
    } catch (err) {
      notification.error({
        message: 'Error refreshing bank feed',
        description: getErrorMsg(err).description
      })
    }
  }, [companyId, mutate])

  return {
    bankFeeds: bankFeeds || [],
    isLoading,
    error: error as Error | null,
    mutate,
    refresh
  }
}

export const usePlaidConnection = (onSuccess?: () => Promise<void>): UsePlaidConnection => {
  const [loading, setLoading] = useState(false);
  const {notification } = App.useApp();
  const onConnect = async (token: string, companyId: number, isUpdate:boolean) => {
    if (!companyId) return;

    try {
      setLoading(true);
      // First register the token with plaid and get bank details
      const bankFeedResponse = await api.post<BankFeedResponse>('connection/bankfeed/token', {
        companyId,
        publicToken: token,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      // For update, we don't need to create a new connection
      if (!isUpdate) {
        // Then create the connection with the bankfeed data
        await api.post(`companies/${companyId}/connection/`, {
          token,
          realmId: bankFeedResponse.data.id,
          provider: 'Plaid',
        });
      }

      notification.success({ message: 'Connected to Plaid' });
      if (onSuccess) await onSuccess();
    } catch (err) {
      notification.error(getErrorMsg(err));
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (connection: PlaidConnectionResponse, companyId: number) => {
    if (!companyId) return;

    try {
      setLoading(true);
      await api.delete(`companies/${companyId}/connection/${connection.keyId}`);
      notification.warning({ message: 'Connection deleted' });
      if (onSuccess) await onSuccess();
    } catch (err) {
      notification.error(getErrorMsg(err));
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    onConnect,
    onDelete
  };
};