import React, { useCallback, useState, useEffect } from 'react'
import { Modal, Flex, Typography, Input } from 'antd';

const { Text } = Typography;

interface ShopifyConnectModalProps {
  visible: boolean;
  onCancel: () => void;
  onConnect: (storeUrl: string) => void;
  loading: boolean;
}

const ShopifyConnectModal: React.FC<ShopifyConnectModalProps> = ({ visible, onCancel, onConnect, loading }) => {
  const [storeUrl, setStoreUrl] = useState<string>('');

  // Track previous loading state to detect when loading completes
  const [prevLoading, setPrevLoading] = useState(loading);

  // Monitor loading state changes to clear the input when loading finishes
  useEffect(() => {
    // If we were loading and now we're not, clear the input
    if (prevLoading && !loading) {
      setStoreUrl('');
    }
    setPrevLoading(loading);
  }, [loading, prevLoading]);

  const cleanShopifyUrl = useCallback((url: string): string => {
    // Remove http:// or https:// if present
    let cleanedUrl = url.replace(/^(https?:\/\/)/, '');
    // Remove www. if present
    cleanedUrl = cleanedUrl.replace(/^www\./, '');
    // Remove anything after .myshopify.com (including /)
    cleanedUrl = cleanedUrl.replace(/(.myshopify\.com).*$/, '$1');

    return cleanedUrl;
  }, []);

  const handleConnect = () => {
    // Make sure URL ends with .myshopify.com
    let finalUrl = storeUrl;
    if (!finalUrl.endsWith('.myshopify.com')) {
      finalUrl = finalUrl + '.myshopify.com';
    }
    onConnect(finalUrl);
    // Don't reset here - we'll reset when loading completes
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Clean the URL
    const cleanedValue = cleanShopifyUrl(inputValue);
    setStoreUrl(cleanedValue);
  };

  return (
      <Modal
          title="Connect to Shopify"
          open={visible}
          onCancel={onCancel}
          onOk={handleConnect}
          okButtonProps={{ disabled: !storeUrl.trim() || loading, loading: loading }}
          cancelButtonProps={{ disabled: loading }}
          closable={!loading}
          maskClosable={!loading}
      >
        <Flex vertical gap={10}>
          <Text>Enter your Shopify store URL:</Text>
          <Input
              placeholder="yourstorename.myshopify.com"
              value={storeUrl}
              onChange={handleInputChange}
              disabled={loading}
          />
        </Flex>
      </Modal>
  );
};

export default ShopifyConnectModal