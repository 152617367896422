import {Button, Divider, Flex, List, Modal, Typography, App, Select} from 'antd'
import { CardIntegration } from 'components/CardIntegration'
import { MatchAccounts } from 'components/MatchAccounts'
import { api } from 'utils/axios'
import { useRole } from 'hooks/useRole'
import { useCompany } from 'hooks/useCompany'
import { useAccounts } from 'hooks/useAccounts'
import { useCompanyStore } from 'store/company.store'
import { IConnectionItem, ConnectionAction, Provider } from 'types/connection.types'
import { RoleType } from 'types/user.types'
import {useState, useEffect, useRef, useMemo} from 'react'
import { useFlags } from 'flagsmith/react'
import ShopifyConnectModal from '../../../components/ShopifyConnectModal'
import { useIntegrationStatus } from '../../../hooks/useIntegrationStatus'
import { useLocation } from 'react-router-dom';
import ConnectionStatusIndicator from '../../../components/ConnectionStatusIndicator';

const { Text } = Typography;

// Define account options for different providers
const accountOptions: Partial<Record<Provider, Array<{ id: string; name: string; isCalc: boolean }>>> = {
  [Provider.Shopify]: [
    { id: 'gross', name: 'Gross Sales', isCalc: false },
    { id: 'discount', name: 'Discounts', isCalc: false },
    { id: 'returns', name: 'Returns', isCalc: false },
    { id: 'net', name: 'Net Sales', isCalc: true },
    { id: 'shipping', name: 'Shipping', isCalc: false },
  ],
  // Add other providers with empty arrays as placeholders
  [Provider.Amazon]: [],
  [Provider.Walmart]: [],
  [Provider.Etsy]: [],
  [Provider.Ebay]: [],
  [Provider.Squarespace]: [],
};

export function Integrations() {
  const { notification } = App.useApp();
  const companyId = useCompanyStore((state) => state.id);
  const { company, revalidate } = useCompany();
  const { accounts, isLoading } = useAccounts();
  const { isAllowed } = useRole(RoleType.Member);
  const [loading, setLoading] = useState(false);
  const [selectedStore, setSelectedStore] = useState<string | null>(null);
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);
  const [shopifyModalVisible, setShopifyModalVisible] = useState(false);
  const [setupModalVisible, setSetupModalVisible] = useState(false);
  const [localMatched, setLocalMatched] = useState<{ [key: string]: Matched }>({});

  const flags = useFlags(['hide_shopify']);

  // Use the integration status hook for everything
  const {
    shopifyStatus,
    isLoading: isLoadingStatus,
    lastUpdated,
    hasActiveConnections,
    activeConnectionsCount,
    connectionsData,
    refreshIntegrationStatus
  } = useIntegrationStatus();

  // Set first store as selected when connections load
  useEffect(() => {
    const shopifyConnections = connectionsData.shopify || [];
    if (shopifyConnections.length > 0 && !selectedStore) {
      const firstConnection = shopifyConnections[0];
      if (firstConnection && firstConnection.id) {
        setSelectedStore(firstConnection.id);
        setSelectedProvider(Provider.Shopify);

        // Also set the initial matched settings
        const matchedSettings = firstConnection.settings?.shopify?.matched || {};
        setLocalMatched(prev => ({
          ...prev,
          [firstConnection.id]: matchedSettings
        }));
      }
    }
    // Later, when more providers are added, you can expand this logic
    // Example:
    // const amazonConnections = connectionsData.amazon || [];
    // if (amazonConnections.length > 0 && !selectedStore) { ... }
  }, [connectionsData, selectedStore]);

  const items: IConnectionItem[] = useMemo(() => {
    console.log('connectionsData', connectionsData);
    console.log('shopifyStatus', shopifyStatus);

    return [
      {
        image: require('assets/shopify.png'),
        title: 'Shopify',
        disabled: flags.hide_shopify.enabled,
        connected: hasActiveConnections,
        status: shopifyStatus
      },
      { image: require('assets/amazon.png'), title: 'Amazon', disabled: true },
      { image: require('assets/walmart.png'), title: 'Walmart', disabled: true },
      { image: require('assets/etsy.png'), title: 'Etsy', disabled: true },
      { image: require('assets/squarespace.png'), title: 'Squarespace', disabled: true },
      { image: require('assets/ebay.png'), title: 'Ebay', disabled: true },
    ];
  }, [flags.hide_shopify.enabled, hasActiveConnections, shopifyStatus, connectionsData]);

  // A ref to track pending changes
  const pendingChangesRef = useRef<{
    connectionId: string;
    value: Matched;
    typeOf: Provider;
  } | null>(null);

  // Location is used for detecting navigation
  const location = useLocation();

  // Create a function to handle saving changes
  const saveChanges = async (changes: { connectionId: string; value: Matched; typeOf: Provider } | null) => {
    if (!changes || !isAllowed || !company?.id) return;

    // Check if connectionId is valid
    if (!changes.connectionId) {
      console.error('Invalid connection ID:', changes.connectionId);
      return;
    }

    try {
      // Build settings object based on provider type
      const settingsPayload = {
        settings: {
          [changes.typeOf.toLowerCase()]: {
            matched: changes.value
          }
        }
      };

      await api.patch(
          `companies/${company.id}/connection/${changes.connectionId}?typeOf=${changes.typeOf}`,
          settingsPayload
      );
      pendingChangesRef.current = null;

      // After successful save, refresh the integration status to get updated settings
      refreshIntegrationStatus();
    } catch (error) {
      console.error('Failed to save changes:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to save account matching changes'
      });
    }
  };

  // Effect to handle route changes and component unmount
  useEffect(() => {
    // Save immediately when location changes
    if (pendingChangesRef.current) {
      saveChanges(pendingChangesRef.current);
    }

    // Cleanup function for unmount
    return () => {
      if (pendingChangesRef.current) {
        saveChanges(pendingChangesRef.current);
      }
    };
  }, [location, company?.id, isAllowed]);

  // Add a debounced save effect to automatically save changes after a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      if (pendingChangesRef.current) {
        saveChanges(pendingChangesRef.current);
      }
    }, 2000); // Save 2 seconds after changes stop

    return () => clearTimeout(timer);
  }, [localMatched]);

  const connectStore = async (storeUrl: string, provider: Provider) => {
    if (!isAllowed) {
      notification.error({ message: 'Error', description: 'You are not allowed to perform this action' });
      return;
    }
    if (!storeUrl) {
      notification.error({ message: 'Error', description: `Please enter your ${provider} store URL` });
      return;
    }

    // Check if store already exists based on the provider
    let storeExists = false;
    const shopifyConnections = connectionsData.shopify || [];
    if (provider === Provider.Shopify && shopifyConnections.length > 0) {
      storeExists = shopifyConnections.some(
          conn => conn.sourceDomain === storeUrl || conn.sourceId === storeUrl
      );
    }
    // Add similar checks for other providers when they're added
    // const amazonConnections = connectionsData.amazon || [];
    // if (provider === Provider.Amazon && amazonConnections.length > 0) { ... }

    if (storeExists) {
      notification.error({ message: 'Error', description: `This ${provider} store is already connected` });
      return;
    }

    setLoading(true);
    try {
      const response = await api.post(`companies/${company?.id || companyId}/connection/link`, {
        provider: provider,
        realmId: storeUrl,
      });

      if (!response.data?.url) {
        throw new Error('No authentication URL received');
      }

      const authWindow = window.open(response.data.url, '_blank', 'width=600,height=600');
      if (!authWindow) {
        throw new Error('Unable to open authentication window. Please check your pop-up blocker settings.');
      }

      const pollTimer = setInterval(async () => {
        if (authWindow.closed) {
          clearInterval(pollTimer);
          await revalidate();
          setLoading(false);

          // Close the appropriate modal based on provider
          if (provider === Provider.Shopify) {
            setShopifyModalVisible(false);
          }
          // Add more provider-specific modal closing as needed

          notification.success({
            message: 'Success',
            description: `${provider} connection added successfully`,
          });

          // Refresh integration status after connection
          refreshIntegrationStatus();
        }
      }, 500);
    } catch (error: any) {
      const errorMessage = typeof error?.response?.data === 'string'
          ? error.response.data
          : error?.response?.data?.message || error?.message || `Failed to connect to ${provider}`;

      notification.error({
        message: 'Error',
        description: errorMessage,
      });
      setLoading(false);
    }
  };

  const connectShopify = (storeUrl: string) => connectStore(storeUrl, Provider.Shopify);

  const onDisconnect = async (connectionId: string, provider: Provider) => {
    if (!isAllowed) {
      notification.error({ message: 'Error', description: 'You are not allowed to perform this action' });
      return;
    }

    setLoading(true);
    try {
      await api.delete(`companies/${company?.id}/connection/${connectionId}?typeOf=${provider}`);

      // Clear the selected store if it was the one disconnected
      if (selectedStore === connectionId) {
        setSelectedStore(null);
        setSelectedProvider(null);
      }

      // Force a revalidation to update data from company
      await revalidate();

      // Clear local matched state for this connection
      setLocalMatched(prev => {
        const newState = {...prev};
        delete newState[connectionId];
        return newState;
      });

      // Show success notification
      notification.success({
        message: 'Success',
        description: `${provider} store disconnected successfully`,
      });

      // Close the setup modal if there are no more connections of this type
      let shouldCloseModal = false;

      if (provider === Provider.Shopify) {
        const shopifyConnections = connectionsData.shopify || [];
        shouldCloseModal = shopifyConnections.length <= 1;
      }
      // Add similar logic for other providers

      if (shouldCloseModal) {
        setSetupModalVisible(false);
      }

      // Refresh the integration status after disconnection
      refreshIntegrationStatus();
    } catch (error: any) {
      const errorMessage = typeof error?.response?.data === 'string'
          ? error.response.data
          : error?.response?.data?.message || error?.message || `Failed to disconnect from ${provider}`;

      notification.error({
        message: 'Error',
        description: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  const onClick = (item: IConnectionItem, action: ConnectionAction) => {
    if (action === ConnectionAction.Connect) {
      if (item.title === 'Shopify') {
        setShopifyModalVisible(true);
      }
      // Add more providers as needed
    } else if (action === ConnectionAction.Setup) {
      setSetupModalVisible(true);
    }
  };

  const onMatchedChange = (value: Matched, connectionId: string, provider: Provider) => {
    if (!isAllowed) {
      notification.error({
        message: 'Error',
        description: 'You are not allowed to perform this action'
      });
      return;
    }

    // Update local state immediately for UI feedback
    setLocalMatched(prev => ({
      ...prev,
      [connectionId]: value
    }));

    // Store pending changes
    pendingChangesRef.current = {
      connectionId,
      value,
      typeOf: provider
    };

    // Explicitly save the changes immediately
    saveChanges({
      connectionId,
      value,
      typeOf: provider
    });
  };

  // Get the selected connection based on the selected provider and store
  const selectedConnection = useMemo(() => {
    if (!selectedStore || !selectedProvider) return null;

    if (selectedProvider === Provider.Shopify) {
      const shopifyConnections = connectionsData.shopify || [];
      return shopifyConnections.find(conn => conn.id === selectedStore) || null;
    }
    // Add cases for other providers

    return null;
  }, [selectedStore, selectedProvider, connectionsData]);

  // Load matched settings when selected store changes
  useEffect(() => {
    if (!selectedStore || !selectedProvider || !selectedConnection) return;

    // Get matched settings based on the provider
    let matchedSettings = {};
    if (selectedProvider === Provider.Shopify) {
      matchedSettings = selectedConnection.settings?.shopify?.matched || {};
    }
    // Add more providers as needed

    setLocalMatched(prev => ({
      ...prev,
      [selectedStore]: matchedSettings
    }));
  }, [selectedStore, selectedProvider, selectedConnection]);

  // Prepare store options based on available connections
  const storeOptions = useMemo(() => {
    const options: Array<{
      label: string;
      value: string;
      provider: Provider;
    }> = [];

    // Add Shopify stores
    const shopifyConnections = connectionsData.shopify || [];
    if (shopifyConnections.length > 0) {
      shopifyConnections.forEach(conn => {
        options.push({
          label: conn.sourceDomain || 'Shopify Store',
          value: conn.id,
          provider: Provider.Shopify
        });
      });
    }

    // Add other providers' stores when they become available
    // const amazonConnections = connectionsData.amazon || [];
    // if (amazonConnections.length > 0) { ... }

    return options;
  }, [connectionsData]);

  // Handle store selection change
  const handleStoreChange = (storeId: string) => {
    const selectedOption = storeOptions.find(option => option.value === storeId);
    if (selectedOption) {
      setSelectedStore(storeId);
      setSelectedProvider(selectedOption.provider);
    }
  };

  // Get the appropriate account options based on the selected provider
  const getAccountOptions = () => {
    if (!selectedProvider) return [];
    return accountOptions[selectedProvider] || [];
  };

  if (!company) return null;

  return (
      <Flex vertical>
        <Divider orientation="left">Integration</Divider>
        <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={items}
            renderItem={(item) => (
                <List.Item key={item.title}>
                  <CardIntegration item={item} onClick={onClick} />
                </List.Item>
            )}
        />

        {hasActiveConnections && (
            <Flex vertical gap={20}>
              <Divider orientation="left">Settings</Divider>
              <Flex gap={8} align="center">
                <Text>Select Store:</Text>
                <Select
                    style={{ width: 300 }}
                    options={storeOptions}
                    value={selectedStore}
                    onChange={handleStoreChange}
                    placeholder="Select a store"
                />
                {isLoadingStatus && <Text type="secondary">(Updating status...)</Text>}
                {lastUpdated && <Text type="secondary" style={{ fontSize: '12px' }}>
                  Last updated: {lastUpdated.toLocaleTimeString()}
                </Text>}
              </Flex>

              {selectedConnection && selectedProvider && (
                  <>
                    <Text>
                      We need to know how you match your e-commerce data to your general ledger accounts
                    </Text>
                    <MatchAccounts
                        value={localMatched[selectedStore || ''] || {}}
                        onChange={(value) => onMatchedChange(value, selectedStore || '', selectedProvider)}
                        accounts={accounts}
                        loading={isLoading || loading}
                        options={getAccountOptions()}
                        placeholder="Select Account(s)"
                        multiple
                        disabled={!isAllowed}
                    />
                  </>
              )}
            </Flex>
        )}

        <Modal
            title="Shopify Integrations"
            open={setupModalVisible}
            onCancel={() => setSetupModalVisible(false)}
            footer={null}
            width={600}
        >
          <Flex vertical gap={10} style={{ marginTop: 10, marginBottom: 20 }}>
            <Text strong>Connected stores ({activeConnectionsCount}):</Text>
            {(connectionsData.shopify || []).map((connection) => {
              const storeName = connection.sourceDomain || 'Store';

              return (
                  <Flex key={connection.id} justify="space-between" align="center">
                    <Text>{storeName}</Text>
                    <Flex gap={8} align="center">
                      <ConnectionStatusIndicator
                          status={connection.state}
                          storeName={storeName}
                          lastUpdated={lastUpdated}
                      />
                      <Button
                          danger
                          size="small"
                          onClick={() => onDisconnect(connection.id, Provider.Shopify)}
                          loading={loading}
                      >
                        Disconnect
                      </Button>
                    </Flex>
                  </Flex>
              );
            })}
            <Divider />
            <Button
                type="primary"
                onClick={() => {
                  setSetupModalVisible(false);
                  setShopifyModalVisible(true);
                }}
            >
              Connect New Store
            </Button>
          </Flex>
        </Modal>

        <ShopifyConnectModal
            visible={shopifyModalVisible}
            onCancel={() => setShopifyModalVisible(false)}
            onConnect={connectShopify}
            loading={loading}
        />
      </Flex>
  );
}

type Matched = { [key: string]: string[] };