import React from 'react';
import { Badge, Tooltip, Space, Typography } from 'antd';
import { SyncOutlined, CheckCircleOutlined, DisconnectOutlined, QuestionOutlined } from '@ant-design/icons';

const { Text } = Typography;

type ConnectionStatusProps = {
    status: number;
    storeName?: string;
    lastUpdated?: Date | null;
};

const getStatusInfo = (status: number) => {
    switch (status) {
        case 0:
            return {
                color: 'default',
                text: 'Disconnected',
                icon: <DisconnectOutlined />,
            };
        case 1:
            return {
                color: 'success',
                text: 'Connected',
                icon: <CheckCircleOutlined />,
            };
        case 2:
            return {
                color: 'processing',
                text: 'Syncing',
                icon: <SyncOutlined spin />,
            };
        case 3:
            return {
                color: 'error',
                text: 'Error',
                icon: <DisconnectOutlined />,
            };
        default:
            return {
                color: 'default',
                text: 'Unknown',
                icon: <QuestionOutlined />,
            };
    }
};

const ConnectionStatusIndicator: React.FC<ConnectionStatusProps> = ({
                                                                        status,
                                                                        storeName,
                                                                        lastUpdated
                                                                    }) => {
    const statusInfo = getStatusInfo(status);

    const tooltipTitle = (
        <div>
            <div>Status: {statusInfo.text}</div>
            {storeName && <div>Store: {storeName}</div>}
            {lastUpdated && (
                <div>Last updated: {lastUpdated.toLocaleTimeString()}</div>
            )}
        </div>
    );

    return (
        <Tooltip title={tooltipTitle} placement="top">
            <Space>
                <Badge
                    status={statusInfo.color as any}
                    text={
                        <Space>
                            {statusInfo.icon}
                            <Text>{statusInfo.text}</Text>
                        </Space>
                    }
                />
            </Space>
        </Tooltip>
    );
};

export default ConnectionStatusIndicator;