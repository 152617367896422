import { useMemo, useEffect } from 'react'
import { useForm } from 'antd/lib/form/Form'
import {Alert, Col, Form, Input, Modal, Row} from 'antd'

import { IDefaultRule } from 'types/default-rules.types'
import { useDefaultRules } from 'hooks/useDefaultRule'
import DocumentEditor from './DocumentEditor'

interface Props {
  open: boolean
  onClose: () => void
  initialValues?: IDefaultRule
  isEditMode?: boolean
  onSuccess?: () => void
  ruleId?: string
}

export function AddRuleModal(props: Props) {
  const { open, onClose, initialValues, onSuccess } = props
  const isEditMode = !!initialValues
  const [form] = useForm()

  const formEntity = Form.useWatch('entity', form)
  const effectiveEntity = initialValues?.entity ?? formEntity

  const { create, update } = useDefaultRules(effectiveEntity)

  const defaultValues = useMemo(
    () => ({
      status: 'active',
    }),
    [],
  )

  // set initial values when edit mode
  useEffect(() => {
    const shouldExit = !open || !isEditMode || !initialValues

    if (shouldExit) {
      return
    }

    form.setFieldsValue({
      name: initialValues.name,
      description: initialValues.description,
      entity: initialValues.entity,
      position: initialValues.position,
    })
  }, [open, initialValues])
  //  const showProjectField = !ruleId && !isEditMode

  const onModalClose = () => {
    form.resetFields()
    onClose()
  }

  const onRuleAdd = (values: any) => {
    const { ...rest } = values

    const onActionSuccess = () => {
      onModalClose()
      onSuccess?.()
    }

    if (isEditMode) {
      update({ ...rest, entity: initialValues?.entity }).then(onActionSuccess)
    } else {
      create({ ...rest }).then(onActionSuccess)
    }
  }

  return (
    <Modal
      width={1080}
      open={open}
      onCancel={onModalClose}
      cancelText="Cancel"
      okText={isEditMode ? 'Update' : 'Create'}
      okButtonProps={{
        form: 'rule-form',
        htmlType: 'submit',
      }}
    >
      <Form form={form} onFinish={onRuleAdd} layout="vertical" id="rule-form" initialValues={defaultValues}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Enter Rule Name"
              name="name"
              rules={[{ required: true, message: 'Please enter the rule name!' }]}
            >
              <Input placeholder="Enter Rule Name" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Entity" name="entity">
              <Input placeholder="Entity" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Position" name="position">
              <Input placeholder="Position" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Description" name="description">
              <DocumentEditor />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Alert
                message="Important Notice"
                description="Please note that rules are not added automatically and need to be programmed separately. Please contact the team developer for further assistance before creating a new rule."
                type="warning"
                showIcon
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
