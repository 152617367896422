import { useEffect, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import {App, Button, Col, Divider, Flex, Form, Input} from 'antd'
import { Row, Select, TreeSelect } from 'antd'

import { Formula } from './components/Formula'

import { IReportAccount, useReportAccounts } from 'hooks/useReportAccounts'
import { useRules } from 'hooks/useRules'
import { customRules } from 'utils/form'
import type { IRule } from 'types/company.types'

export function ReviewRule() {
  const {notification } = App.useApp();
  const match = useMatch('company/:companyId/review/rules/:id')
  const navigate = useNavigate()

  const rules = useRules()
  const accounts = useReportAccounts()

  const [form] = Form.useForm<IRule>()
  const [target, setTarget] = useState(false)

  const ruleId = match?.params?.id ? +match.params.id : undefined
  const companyId = match?.params?.companyId

  // Early return if no companyId is found
  useEffect(() => {
    if (!companyId) {
      notification.error({ message: 'Error', description: 'Company ID is required' })
      navigate('/', { replace: true })
      return
    }
  }, [companyId, navigate])


  const rule = ruleId ? rules.rules?.find((r) => r.id === ruleId) : undefined
  useEffect(() => {
    if (rule) {
      const account = rule.account?.key
      form.setFieldsValue({ ...rule, account })
    }
  }, [rule])

  const onCancel = () => {
    if (!companyId) return
    /*navigate('..', { relative: 'path' })*/
    navigate('/company/'+match.params.companyId+'/review/', { relative: 'path' })
  }

  const onSave = (values: IRule) => {

    if (!values.account) return notification.error({ message: 'Error', description: 'Account is required' })

    const flatAccs = flatTree(accounts.accounts)
    const account = flatAccs.find((acc) => acc.key === values.account)

    const { id, key, label, reportType } = account || {}
    const acc = { id, key, label, reportType }
    const formula = values.formula[0]

    const active = values.active !== undefined ? values.active : true


    const handleSuccess = () => {
      notification.success({ message: 'Success', description: rule?.id ? 'Rule updated' : 'Rule saved' })
      navigate(`/company/${companyId}/review/`, { replace: true })
    }

    if (rule?.id) {
      return rules.update({ ...rule, ...values, account: acc, formula }).then(handleSuccess)
    }

    rules.create({ ...values, account: acc, formula, active }).then(handleSuccess)
  }

  const onValuesChange = (changedValues: Partial<IRule>) => {
    if (changedValues.account) {
      const isTotal = changedValues.account.includes('profitandloss')
      setTarget(isTotal)
      form.setFieldsValue({ target: 'total' })
    }
  }

  return (
    <Flex vertical>
      <Divider orientation="left">Rule Details</Divider>
      <Form form={form} onFinish={onSave} onValuesChange={onValuesChange}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Name" name="name" rules={customRules.name}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="Description" name="description" rules={customRules.description}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Rule</Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Account" name="account" rules={customRules.account}>
              <TreeSelect
                loading={accounts.isLoading}
                treeData={accounts.accounts}
                fieldNames={{ label: 'label', value: 'key', children: 'rows' }}
                placeholder="Please select"
                allowClear
                showSearch
                filterTreeNode={(input, option: any) =>
                  (option?.label?.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Target" name="target" initialValue={'total'}>
              <Select disabled={target}>
                <Select.Option value="total">Total</Select.Option>
                <Select.Option value="balance">Balance</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Needs to be" name="condition" initialValue={'=='}>
              <Select>
                <Select.Option value="==">Equal to</Select.Option>
                <Select.Option value=">">More than</Select.Option>
                <Select.Option value="<">Less than</Select.Option>
                <Select.Option value=">=">More than or equal to</Select.Option>
                <Select.Option value="<=">Less than or equal to</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Formula</Divider>
        <Flex vertical gap={10}>
          <Form.Item label="" name="formula" rules={customRules.formula}>
            <Formula onChange={console.log} accounts={accounts.accounts} defaultvalue={rule?.formula} />
          </Form.Item>
        </Flex>
        <Divider />
        <Flex gap={10} justify="flex-end">
          <Button type="primary" style={submitStyle} htmlType="submit" loading={rules.isLoading}>
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Flex>
      </Form>
    </Flex>
  )
}

function flatTree(treeData: IReportAccount[]): IReportAccount[] {
  return treeData.reduce((acc: IReportAccount[], node: IReportAccount) => {
    if (node.rows) return [...acc, node, ...flatTree(node.rows)]
    return [...acc, node]
  }, [] as IReportAccount[])
}

const submitStyle: React.CSSProperties = {
  width: 200,
}