import { IRole, IUser } from './user.types'
import {ISettings} from "./connection.types";
import {ITeam} from "./team.types";

export interface ICompany {
  id: number
  qbo_id: string
  name: string
  alias?: string
  image: string | null
  team: ITeam
  role: IRole
  connections: ICompanyConnection[]
  entity: number | null
  lastaccessed: string
  isPaid?: string
  owner?: IUser
  accessLevel?: number
  progress?: number
  file_logo_id?: string
  logoUrl?: string 
  settings: ICompanySettings | null
}

export interface ICompanySettings {
  accountingMethod?: string
  currency?: string
  payroll?: {
    matched: {
      [key: string]: string[]
    }
  }
  // merchant?: {
  //   matched: {
  //     [key: string]: string[]
  //   }
  // }
  uncat?: {
    selected: string[]
  }
}

export enum ConnectionType {
  QBO = 1,
  Plaid = 2,
  Rutter = 3,
  Finch = 4,
  Shopify = 5
}
export enum IntegrationStatus {
  Failed,
  Active,
  Pending,
  Refreshing = 4,
}

export interface ICompanyConnection {
  keyId: number
  id: ConnectionType
  name: string
  status: IntegrationStatus
  connectionInfo: any
  type: {
    id: ConnectionType
    name: string
  }
  settings?: ISettings
}

export interface IRule {
  id: number
  name: string
  description: string
  target: string
  condition: string
  account: any
  formula: any
  active: boolean
  created_at: string
  updated_at: string
}

export interface IQboCustomer {
  Id: number
  Balance: number
  DisplayName: string
}

export interface IQboVendor {
  Id: number
  Balance: number
  DisplayName: string
}
