import { useState, useEffect, useCallback } from 'react';
import { useCompany } from './useCompany';
import {
    connectToWebSocket,
    onSocketEvent,
    offSocketEvent,
    ensureSocketConnected,
    GATEWAY_TYPES
} from '../utils/websocket';

export enum IntegrationStatus {
    Inactive = 0,
    Active = 1,
    Reauth = 3,
    Syncing = 4,
}

// Define the connection data structure
interface ConnectionData {
    id: string;
    companyId: number;
    sourceId: string;
    sourceDomain: string;
    state: IntegrationStatus;
    settings?: {
        shopify?: {
            matched?: { [key: string]: string[] }
        }
    };
}

interface IntegrationData {
    shopify?: ConnectionData[];
    // Add other integration types as needed
}

interface UseIntegrationStatusReturn {
    shopifyStatus: IntegrationStatus;
    isLoading: boolean;
    lastUpdated: Date | null;
    hasActiveConnections: boolean;
    hasSyncingConnections: boolean;
    hasErrorConnections: boolean;
    activeConnectionsCount: number;
    connectionsData: IntegrationData;
    refreshIntegrationStatus: () => Promise<void>;
}

export const useIntegrationStatus = (): UseIntegrationStatusReturn => {
    const { company } = useCompany();
    const [shopifyStatus, setShopifyStatus] = useState<IntegrationStatus>(IntegrationStatus.Inactive);
    const [isLoading, setIsLoading] = useState(false);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    // Add state for connection counts
    const [hasActiveConnections, setHasActiveConnections] = useState(false);
    const [hasSyncingConnections, setHasSyncingConnections] = useState(false);
    const [hasErrorConnections, setHasErrorConnections] = useState(false);
    const [activeConnectionsCount, setActiveConnectionsCount] = useState(0);

    // Add state for raw connection data
    const [connectionsData, setConnectionsData] = useState<IntegrationData>({});

// Updated handleConnectionStatus function for useIntegrationStatus.ts
    const handleConnectionStatus = useCallback((data: any) => {

        setConnectionsData(prevConnectionsData => {
            let newConnectionsData = { ...prevConnectionsData };

            // Handle different data formats
            if (Array.isArray(data)) {
                // If receiving a complete array of connections, replace the shopify array
                newConnectionsData.shopify = data;
            } else if (data && typeof data === 'object') {
                // Check if data is a shopify property with array
                if (data.shopify && Array.isArray(data.shopify)) {
                    // Check if this is a single connection update vs. full refresh
                    if (data.shopify.length === 1 && newConnectionsData.shopify && newConnectionsData.shopify.length > 0) {
                        // Looks like a single store update wrapped in ConnectionStatusResponse
                        const newConnection = data.shopify[0];
                        const existingConnections = newConnectionsData.shopify || [];

                        // Find if this connection already exists
                        const connectionIndex = existingConnections.findIndex(
                            conn => conn.id === newConnection.id
                        );

                        if (newConnection.state === IntegrationStatus.Inactive) {
                            // Remove the connection if state is Inactive (0)
                            newConnectionsData.shopify = existingConnections.filter(
                                conn => conn.id !== newConnection.id
                            );
                        } else if (connectionIndex >= 0) {
                            // Update existing connection
                            const updatedConnections = [...existingConnections];
                            updatedConnections[connectionIndex] = newConnection;
                            newConnectionsData.shopify = updatedConnections;
                        } else {
                            // Add new connection
                            newConnectionsData.shopify = [...existingConnections, newConnection];
                        }
                    } else {
                        // Likely a full refresh with multiple connections or initial load
                        const existingConnections = newConnectionsData.shopify || [];
                        const newConnectionIds = new Set(data.shopify.map((conn: { id: any; }) => conn.id));

                        // Keep existing connections that aren't in the new data
                        // (ensures we don't lose connections if server sent a partial update)
                        const connectionsToKeep = existingConnections.filter(
                            conn => !newConnectionIds.has(conn.id) && conn.state !== IntegrationStatus.Inactive
                        );

                        // Merge with new connections
                        newConnectionsData.shopify = [
                            ...connectionsToKeep,
                            ...data.shopify.filter((conn: { state: IntegrationStatus; }) => conn.state !== IntegrationStatus.Inactive)
                        ];
                    }
                }
                // Check if it's a single connection update (individual connection object)
                else if (data.id && typeof data.state === 'number') {
                    // It's a single connection update
                    const existingConnections = newConnectionsData.shopify || [];

                    if (data.state === IntegrationStatus.Inactive) {
                        // Remove the connection if state is Inactive (0)
                        newConnectionsData.shopify = existingConnections.filter(
                            conn => conn.id !== data.id
                        );
                    } else {
                        // Update or add the connection
                        const connectionIndex = existingConnections.findIndex(
                            conn => conn.id === data.id
                        );

                        if (connectionIndex >= 0) {
                            // Update existing connection
                            const updatedConnections = [...existingConnections];
                            updatedConnections[connectionIndex] = data;
                            newConnectionsData.shopify = updatedConnections;
                        } else {
                            // Add new connection
                            newConnectionsData.shopify = [...existingConnections, data];
                        }
                    }
                }
            }

            return newConnectionsData;
        });

        // After updating the connections data, calculate status metrics
        // Use the updated connections data to recalculate all status metrics
        setConnectionsData(newData => {
            // This is where you call your existing updateConnectionStatus method
            // or we can inline the functionality here

            // Array to store all Shopify connection states
            const shopifyStates: number[] = [];

            // Process Shopify connections data
            const shopifyData = newData.shopify || [];

            if (shopifyData.length > 0) {
                let activeCount = 0;
                let hasActive = false;
                let hasSyncing = false;
                let hasError = false;

                shopifyData.forEach((conn: ConnectionData) => {
                    if (typeof conn.state === 'number') {
                        shopifyStates.push(conn.state);

                        // Count active connections
                        if (conn.state === IntegrationStatus.Active) {
                            activeCount++;
                            hasActive = true;
                        }

                        // Track if any connection is syncing
                        if (conn.state === IntegrationStatus.Syncing) {
                            hasSyncing = true;
                        }

                        // Track if any connection has an error
                        if (conn.state === IntegrationStatus.Reauth) {
                            hasError = true;
                        }
                    }
                });

                // Update connection status counts
                setHasActiveConnections(hasActive);
                setHasSyncingConnections(hasSyncing);
                setHasErrorConnections(hasError);
                setActiveConnectionsCount(activeCount);
            } else {
                // No shopify connections
                setHasActiveConnections(false);
                setHasSyncingConnections(false);
                setHasErrorConnections(false);
                setActiveConnectionsCount(0);
            }

            // Determine the consolidated status with priority
            let consolidatedStatus = IntegrationStatus.Inactive;

            if (shopifyStates.includes(IntegrationStatus.Syncing)) {
                consolidatedStatus = IntegrationStatus.Syncing;
            } else if (shopifyStates.includes(IntegrationStatus.Reauth)) {
                consolidatedStatus = IntegrationStatus.Reauth;
            } else if (shopifyStates.includes(IntegrationStatus.Active)) {
                consolidatedStatus = IntegrationStatus.Active;
            }

            setShopifyStatus(consolidatedStatus);

            return newData;
        });

        setLastUpdated(new Date());
        setIsLoading(false);
    }, []);

    // Function to handle errors
    const handleError = useCallback((error: any) => {
        console.error('Connection status error:', error);
        setIsLoading(false);
    }, []);

    // Function to initialize connection
    const initializeConnectionStatus = useCallback(async () => {
        if (!company?.id) return;

        setIsLoading(true);

        try {
            // Register event handlers
            onSocketEvent('connectionStatus', handleConnectionStatus, GATEWAY_TYPES.CONNECTION_STATUS);
            onSocketEvent('error', handleError, GATEWAY_TYPES.CONNECTION_STATUS);

            // Connect to the WebSocket
            const connected = await ensureSocketConnected(GATEWAY_TYPES.CONNECTION_STATUS);

            if (connected) {
                // Get the socket
                const socket = await connectToWebSocket(GATEWAY_TYPES.CONNECTION_STATUS);

                // Make sure the socket exists and is connected
                if (socket?.connected) {
                    // Send ready with company ID
                    socket.emit('ready', {
                        clientTime: new Date().toISOString(),
                        companyId: company.id
                    });

                    // Subscribe to connection status updates
                    socket.emit('subscribeConnectionStatus', { companyId: company.id });
                } else {
                    console.error('Socket not connected when trying to subscribe');
                    setIsLoading(false);
                }
            } else {
                console.error('Failed to connect to connection status gateway');
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error subscribing to connection status:', error);
            setIsLoading(false);
        }
    }, [company?.id, handleConnectionStatus, handleError]);

    // Add a refresh function to manually request updated status
    const refreshIntegrationStatus = useCallback(async () => {
        if (!company?.id) return;

        try {
            // Get the socket
            const socket = await connectToWebSocket(GATEWAY_TYPES.CONNECTION_STATUS);

            if (socket?.connected) {
                // Set loading state
                setIsLoading(true);

                // Re-emit ready to refresh the connection
                socket.emit('ready', {
                    clientTime: new Date().toISOString(),
                    companyId: company.id
                });

                // Request updated status
                socket.emit('requestConnectionStatus', { companyId: company.id });

                // If there's no response after 5 seconds, stop loading
                setTimeout(() => {
                    setIsLoading(prev => {
                        if (prev) {

                            return false;
                        }
                        return prev;
                    });
                }, 5000);
            } else {
                // If not connected, try to reconnect
                await initializeConnectionStatus();
            }
        } catch (error) {
            console.error('Error refreshing integration status:', error);
            setIsLoading(false);
        }
    }, [company?.id, initializeConnectionStatus]);

    useEffect(() => {

        initializeConnectionStatus();

        // Cleanup
        return () => {
            offSocketEvent('connectionStatus', handleConnectionStatus, GATEWAY_TYPES.CONNECTION_STATUS);
            offSocketEvent('error', handleError, GATEWAY_TYPES.CONNECTION_STATUS);

            // Unsubscribe
            connectToWebSocket(GATEWAY_TYPES.CONNECTION_STATUS)
                .then(socket => {
                    if (socket?.connected) {
                        socket.emit('unsubscribeConnectionStatus');
                    }
                })
                .catch(err => console.error('Error during cleanup:', err));
        };
    }, [company?.id, handleConnectionStatus, handleError, initializeConnectionStatus]);

    // Return the refresh function as part of the hook's interface
    return {
        shopifyStatus,
        isLoading,
        lastUpdated,
        hasActiveConnections,
        hasSyncingConnections,
        hasErrorConnections,
        activeConnectionsCount,
        connectionsData,
        refreshIntegrationStatus,
    };
};