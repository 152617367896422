import { ConnectionType, IntegrationStatus } from './company.types'

export interface IConnectionItem {
  image: string
  title: string
  disabled?: boolean
  connected?: boolean
  status?: string | number
}

export enum ConnectionAction {
  Connect,
  Setup,
}


export interface IConnectionData {
  connectionInfo: any;
  keyId: any;
  id: ConnectionType;
  name: string;
  status: IntegrationStatus;
  type: {
    id: ConnectionType;
    name: string;
  };
  settings?: ISettings;
}


export interface Integrations {
  name: string;
  integrationKeyId: number;
}

export interface ISettings {
  shopify?: {
    matched: {
      [key: string]: string[]
    }
  }
}


export enum Provider {
  Rutter = 'Rutter',
  QBO = 'QBO',
  Stripe = 'Stripe',
  Finch = 'Finch',
  Plaid = 'Plaid',
  Shopify = 'Shopify',
  Amazon = 'Amazon',
  Squarespace = 'Squarespace',
  Walmart = 'Walmart',
    Etsy = 'Etsy',
  Ebay = 'Ebay',
}
