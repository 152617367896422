import { useState, useEffect, useCallback, useRef } from 'react';
import {
    onSocketEvent,
    offSocketEvent,
    isSocketConnected,
    ensureSocketConnected,
} from 'utils/websocket';
import { getCurrentUser } from 'utils/firebase';

// Define the type for event handlers
type EventHandler = (data: any) => void;

// Define the type for the WebSocket API
interface WebSocketAPI {
    connected: boolean;
    connecting: boolean;
    lastError: Error | null;
    registerHandler: (event: string, handler: EventHandler) => void;
    unregisterHandler: (event: string, handler: EventHandler) => void;
    ensureConnected: () => Promise<boolean>;
    disconnect: () => void;
}

// Store registered handlers globally to prevent duplicate registrations
const globalHandlers = new Map<string, Set<EventHandler>>();

/**
 * Custom hook for managing WebSocket connections with efficient event handling.
 * Provides a stable API for components to interact with the WebSocket.
 */
export function useWebSocket(): WebSocketAPI {
    // Track local connection state
    const [connected, setConnected] = useState<boolean>(isSocketConnected());
    const [connecting, setConnecting] = useState<boolean>(false);
    const [lastError, setLastError] = useState<Error | null>(null);

    // Track initialization and cleanup
    const isActiveRef = useRef(true);
    const connectionCheckIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    // Check connection status and update state
    const checkConnectionStatus = useCallback(() => {
        const currentStatus = isSocketConnected();

        setConnected((prev) => {
            if (prev !== currentStatus) {
                console.log(`WebSocket connection status changed: ${currentStatus ? 'connected' : 'disconnected'}`);
                return currentStatus;
            }
            return prev;
        });

        return currentStatus;
    }, []);

    // Start periodic connection checker
    const startConnectionChecker = useCallback(() => {
        if (connectionCheckIntervalRef.current) {
            clearInterval(connectionCheckIntervalRef.current);
        }

        // Check immediately first
        checkConnectionStatus();

        // Then set up regular interval checking
        connectionCheckIntervalRef.current = setInterval(() => {
            if (!isActiveRef.current) return;
            const status = checkConnectionStatus();

            // If we think we're connected but actually disconnected, try to reconnect
            if (!status && connected && !connecting && isActiveRef.current) {
                // Schedule reconnect with exponential backoff
                scheduleReconnect();
            }
        }, 10000); // Check every 10 seconds
    }, [checkConnectionStatus, connected, connecting]);

    // Schedule reconnection with exponential backoff
    const scheduleReconnect = useCallback(() => {
        if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
        }

        // Calculate backoff time based on number of consecutive failures
        // Implement proper exponential backoff with jitter
        const backoffMs = Math.min(30000, 1000 * Math.pow(2, Math.min(5, reconnectTimeoutRef.current ? 5 : 0)));
        const jitter = Math.random() * 0.3 * backoffMs;
        const delay = backoffMs + jitter;

        reconnectTimeoutRef.current = setTimeout(() => {
            reconnectTimeoutRef.current = null;
            if (isActiveRef.current && !isSocketConnected()) {
                ensureConnected().catch(console.error);
            }
        }, delay);
    }, []);

    // Register event handler with deduplication
    const registerHandler = useCallback((event: string, handler: EventHandler): void => {
        if (!event || typeof handler !== 'function') {
            console.warn('Invalid event or handler provided', { event, handlerType: typeof handler });
            return;
        }

        // Get or create the set of handlers for this event
        if (!globalHandlers.has(event)) {
            globalHandlers.set(event, new Set());
        }

        const handlers = globalHandlers.get(event)!;

        // Skip if this exact handler is already registered
        if (handlers.has(handler)) {
            console.debug(`Handler for ${event} already registered, skipping duplicate`);
            return;
        }

        // Add to global set
        handlers.add(handler);

        // Register with socket if connected
        if (isSocketConnected()) {
            onSocketEvent(event, handler);
            console.debug(`Registered handler for ${event} event with socket`);
        } else {
            console.debug(`Stored handler for ${event} event (socket not connected)`);
        }
    }, []);

    // Unregister event handler
    const unregisterHandler = useCallback((event: string, handler: EventHandler): void => {
        if (!event || typeof handler !== 'function') return;

        const handlers = globalHandlers.get(event);

        if (handlers?.has(handler)) {
            // Remove from global set
            handlers.delete(handler);

            // Remove from socket if connected
            if (isSocketConnected()) {
                offSocketEvent(event, handler);
            }

            // Clean up empty sets
            if (handlers.size === 0) {
                globalHandlers.delete(event);
            }
        }
    }, []);

    // Register all stored handlers with a new socket connection
    const registerAllHandlers = useCallback(() => {
        if (!isSocketConnected()) return;

        globalHandlers.forEach((handlers, event) => {
            handlers.forEach((handler) => {
                // First remove any existing registrations to avoid duplicates
                offSocketEvent(event, handler);
                // Then register
                onSocketEvent(event, handler);
            });
            console.debug(`Registered ${handlers.size} handlers for event: ${event}`);
        });
    }, []);

    // Ensure connection is established
    const ensureConnected = useCallback(async (): Promise<boolean> => {
        // If already connected, return early
        if (isSocketConnected()) {
            return true;
        }

        // If already connecting, don't start another connection attempt
        if (connecting) {
            console.debug('Connection attempt already in progress');
            return false;
        }

        setConnecting(true);
        setLastError(null);

        try {

            // Get current user first
            const user = await getCurrentUser();
            if (!user) {
                console.warn('No authenticated user, cannot connect WebSocket');
                return false;
            }

            const connected = await ensureSocketConnected();

            if (connected) {

                // Register all handlers with new connection
                registerAllHandlers();

                // Update connection status
                checkConnectionStatus();
                return true;
            } else {
                console.warn('WebSocket connection attempt failed');
                return false;
            }
        } catch (error) {
            console.error('Error ensuring WebSocket connection:', error);
            setLastError(error instanceof Error ? error : new Error(String(error)));
            return false;
        } finally {
            setConnecting(false);
        }
    }, [checkConnectionStatus, connecting, registerAllHandlers]);

    // Disconnect WebSocket
    const disconnect = useCallback(() => {
        if (connectionCheckIntervalRef.current) {
            clearInterval(connectionCheckIntervalRef.current);
            connectionCheckIntervalRef.current = null;
        }

        if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
            reconnectTimeoutRef.current = null;
        }

        // Import and call the disconnect function
        import('utils/websocket').then(({ disconnectFromWebSocket }) => {
            disconnectFromWebSocket();
            setConnected(false);
        });
    }, []);

    // Initialize connection on mount
    useEffect(() => {
        isActiveRef.current = true;

        // Start connection checker
        startConnectionChecker();

        // If not already connected, try connecting
        if (!isSocketConnected() && !connecting) {
            ensureConnected().catch(console.error);
        }

        // Clean up on unmount
        return () => {
            isActiveRef.current = false;

            if (connectionCheckIntervalRef.current) {
                clearInterval(connectionCheckIntervalRef.current);
            }

            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
        };
    }, [connecting, ensureConnected, startConnectionChecker]);

    // Create API object with stable references
    return {
        connected,
        connecting,
        lastError,
        registerHandler,
        unregisterHandler,
        ensureConnected,
        disconnect
    };
}