import { DefaultEventsMap } from '@socket.io/component-emitter';
import io, { Socket } from 'socket.io-client';
import { getCurrentUser, getIdToken } from 'utils/firebase';

// Connection state tracking
const CONNECTION_STATES = {
  DISCONNECTED: 'disconnected',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  ERROR: 'error'
};

// Gateway types
export const GATEWAY_TYPES = {
  RECONCILIATION: 'reconciliation',
  CONNECTION_STATUS: 'connection-status'
};

// Gateway configuration
const GATEWAY_CONFIG = {
  [GATEWAY_TYPES.RECONCILIATION]: {
    path: '/socket.io',
    namespace: '' // default namespace
  },
  [GATEWAY_TYPES.CONNECTION_STATUS]: {
    path: '/connection-status',
    namespace: ''
  }
};

// Enhanced Socket Manager with multi-gateway support
class SocketManager {
  private static instance: SocketManager;

  // Track sockets for different gateways
  private sockets = new Map<string, {
    socket: Socket<DefaultEventsMap, DefaultEventsMap> | null,
    connectionState: string,
    connectPromise: Promise<Socket | null> | null,
    connectedUserId: string | null,
    reconnectAttempts: number,
    lastConnectionError: Error | null,
    reconnectTimer: NodeJS.Timeout | null,
    heartbeatTimer: NodeJS.Timeout | null,
    pingTimeoutTimer: NodeJS.Timeout | null,
    connectionId: string,
    readySent: boolean,
    registeredSocketEvents: Set<string>
  }>();

  private maxReconnectAttempts = 10;

  // Track operations in progress across all gateways
  private activeOperations = new Map<string, {
    id: string,
    type: string,
    gatewayType: string,
    startTime: number,
    lastUpdate: number
  }>();

  // Use Map for efficient handler tracking with unique IDs per gateway
  private eventHandlers = new Map<string, Map<string, Map<string, Function>>>();

  // Use WeakMap to track handlers by function reference for cleanup
  private handlerWeakMap = new WeakMap<Function, string>();

  // Track socket connection health metrics per gateway
  private metrics = new Map<string, {
    totalConnections: number,
    successfulConnections: number,
    failedConnections: number,
    disconnections: number,
    messagesSent: number,
    messagesReceived: number,
    averageLatency: number,
    latencySamples: number
  }>();

  private constructor() {
    console.log('Creating SocketManager instance with multi-gateway support');

    // Initialize metrics for each gateway type
    for (const gatewayType of Object.values(GATEWAY_TYPES)) {
      this.metrics.set(gatewayType, {
        totalConnections: 0,
        successfulConnections: 0,
        failedConnections: 0,
        disconnections: 0,
        messagesSent: 0,
        messagesReceived: 0,
        averageLatency: 0,
        latencySamples: 0
      });

      // Initialize event handlers map for each gateway
      this.eventHandlers.set(gatewayType, new Map());

      // Initialize socket state for each gateway
      this.sockets.set(gatewayType, {
        socket: null,
        connectionState: CONNECTION_STATES.DISCONNECTED,
        connectPromise: null,
        connectedUserId: null,
        reconnectAttempts: 0,
        lastConnectionError: null,
        reconnectTimer: null,
        heartbeatTimer: null,
        pingTimeoutTimer: null,
        connectionId: this.generateUniqueId(),
        readySent: false,
        registeredSocketEvents: new Set<string>()
      });
    }

    // Start heartbeats for all gateways
    for (const gatewayType of Object.values(GATEWAY_TYPES)) {
      this.startHeartbeat(gatewayType);
    }

    // Add periodic cleanup of stale operations
    setInterval(() => this.cleanupStaleOperations(), 1800000); // every 30 minutes

    // Listen for online/offline events to proactively handle connection changes
    if (typeof window !== 'undefined') {
      window.addEventListener('online', this.handleOnline);
      window.addEventListener('offline', this.handleOffline);

      // Also handle visibility changes to detect when the user returns to the app
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }
  }

  static getInstance(): SocketManager {
    if (!SocketManager.instance) {
      SocketManager.instance = new SocketManager();
    }
    return SocketManager.instance;
  }

  // Generate a unique ID for various tracking purposes
  private generateUniqueId(): string {
    return Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
  }

  // Handle browser online event
  private handleOnline = (): void => {
    console.log('Network connection restored, reconnecting WebSockets...');

    // Attempt to reconnect all gateways
    for (const gatewayType of Object.values(GATEWAY_TYPES)) {
      const socketState = this.sockets.get(gatewayType);
      if (socketState &&
          !socketState.socket?.connected &&
          socketState.connectionState !== CONNECTION_STATES.CONNECTING) {
        this.attemptReconnect(gatewayType, 1000); // Small delay to ensure network is stable
      }
    }
  };

  // Handle browser offline event
  private handleOffline = (): void => {
    console.log('Network connection lost, WebSockets may disconnect');

    // Update state for all gateways
    for (const gatewayType of Object.values(GATEWAY_TYPES)) {
      const socketState = this.sockets.get(gatewayType);
      if (socketState && socketState.socket?.connected) {
        socketState.connectionState = CONNECTION_STATES.ERROR;
      }
    }
  };

  // Handle tab visibility changes
  private handleVisibilityChange = (): void => {
    if (document.visibilityState === 'visible') {
      console.log('Tab became visible, checking WebSocket connections');

      // Check all gateways
      for (const gatewayType of Object.values(GATEWAY_TYPES)) {
        const socketState = this.sockets.get(gatewayType);

        // If we think we're connected but the socket is actually disconnected
        if (socketState &&
            socketState.connectionState === CONNECTION_STATES.CONNECTED &&
            !socketState.socket?.connected) {
          console.log(`${gatewayType} WebSocket disconnected while tab was hidden, reconnecting`);
          this.attemptReconnect(gatewayType, 500);
        }
      }
    }
  };

  // Start a heartbeat to check connection health periodically
  private startHeartbeat(gatewayType: string): void {
    const socketState = this.sockets.get(gatewayType);
    if (!socketState) return;

    if (socketState.heartbeatTimer) {
      clearInterval(socketState.heartbeatTimer);
      socketState.heartbeatTimer = null;
    }

    socketState.heartbeatTimer = setInterval(() => {
      if (socketState.socket?.connected) {
        const startTime = Date.now();

        // Clear any existing ping timeout
        if (socketState.pingTimeoutTimer) {
          clearTimeout(socketState.pingTimeoutTimer);
          socketState.pingTimeoutTimer = null;
        }

        // Send ping with timestamp for latency calculation
        socketState.socket.emit('ping', {
          timestamp: startTime,
          connectionId: socketState.connectionId,
          reconnectAttempts: socketState.reconnectAttempts,
          gatewayType
        });

        // Set up response timeout
        socketState.pingTimeoutTimer = setTimeout(() => {
          console.warn(`${gatewayType} socket ping timeout - connection may be broken`);

          // Force disconnect and reconnect if ping fails
          if (socketState.socket?.connected) {
            const metrics = this.metrics.get(gatewayType);
            if (metrics) metrics.disconnections++;

            socketState.socket.disconnect();
            socketState.connectionState = CONNECTION_STATES.ERROR;
            this.attemptReconnect(gatewayType);
          }
        }, 5000);

        // Listen for pong response
        const pongHandler = (data: any) => {
          if (socketState.pingTimeoutTimer) {
            clearTimeout(socketState.pingTimeoutTimer);
            socketState.pingTimeoutTimer = null;
          }

          const endTime = Date.now();
          const latency = endTime - (data.timestamp || startTime);

          // Update latency metrics
          const metrics = this.metrics.get(gatewayType);
          if (metrics) {
            metrics.latencySamples++;
            metrics.averageLatency =
                (metrics.averageLatency * (metrics.latencySamples - 1) + latency) /
                metrics.latencySamples;
          }

          // Reset reconnect attempts on successful ping
          if (socketState.reconnectAttempts > 0) {
            socketState.reconnectAttempts = 0;
          }

          socketState.socket?.off('pong', pongHandler);
        };

        socketState.socket.once('pong', pongHandler);
      } else if (socketState.connectionState === CONNECTION_STATES.CONNECTED) {
        // Socket thinks it's connected but the actual socket is disconnected
        console.warn(`${gatewayType} socket heartbeat detected connection mismatch, reconnecting`);
        socketState.connectionState = CONNECTION_STATES.ERROR;
        this.attemptReconnect(gatewayType);
      }
    }, 15000); // Check every 15 seconds
  }

  async connect(gatewayType: string = GATEWAY_TYPES.RECONCILIATION): Promise<Socket | null> {
    // Make sure the gateway type is valid
    if (!Object.values(GATEWAY_TYPES).includes(gatewayType)) {
      console.error(`Invalid gateway type: ${gatewayType}`);
      return null;
    }

    const socketState = this.sockets.get(gatewayType);
    if (!socketState) {
      console.error(`Socket state not found for gateway: ${gatewayType}`);
      return null;
    }

    // If already connected, return the socket
    if (socketState.socket?.connected && socketState.connectionState === CONNECTION_STATES.CONNECTED) {
      return socketState.socket;
    }

    // If there's already a connection attempt in progress, return that promise
    if (socketState.connectPromise) {
      return socketState.connectPromise;
    }

    // Start a new connection attempt
    const metrics = this.metrics.get(gatewayType);
    if (metrics) metrics.totalConnections++;

    socketState.connectionState = CONNECTION_STATES.CONNECTING;
    socketState.connectPromise = this._connect(gatewayType);

    try {
      const result = await socketState.connectPromise;

      if (metrics) {
        if (result) {
          metrics.successfulConnections++;
        } else {
          metrics.failedConnections++;
        }
      }

      return result;
    } finally {
      // Clear the promise when done
      socketState.connectPromise = null;
    }
  }

  private async _connect(gatewayType: string): Promise<Socket | null> {
    const socketState = this.sockets.get(gatewayType);
    if (!socketState) {
      console.error(`Socket state not found for gateway: ${gatewayType}`);
      return null;
    }

    try {
      // Get the Firebase UID
      const firebaseUser = await getCurrentUser();
      const firebaseUid = firebaseUser?.uid;

      if (!firebaseUid) {
        console.error('Missing Firebase user ID');
        socketState.lastConnectionError = new Error('Authentication required');
        socketState.connectionState = CONNECTION_STATES.ERROR;
        return null;
      }

      // If already connected with this user ID, return existing socket
      if (socketState.socket?.connected && socketState.connectedUserId === firebaseUid) {
        console.log(`Already connected to ${gatewayType} with user ID: ${firebaseUid}`);
        socketState.connectionState = CONNECTION_STATES.CONNECTED;
        return socketState.socket;
      }

      // Reset connection tracking
      socketState.lastConnectionError = null;
      socketState.registeredSocketEvents.clear();
      socketState.readySent = false;

      // Disconnect any existing socket before creating a new one
      if (socketState.socket) {
        console.log(`Disconnecting existing ${gatewayType} socket before reconnection`);
        const metrics = this.metrics.get(gatewayType);
        if (metrics) metrics.disconnections++;

        socketState.socket.disconnect();
        socketState.socket = null;
      }

      // Get authentication token
      const token = await getIdToken();
      if (!token) {
        console.error('Missing authentication token');
        socketState.lastConnectionError = new Error('Authentication failed');
        socketState.connectionState = CONNECTION_STATES.ERROR;
        return null;
      }

      // Determine environment for server URL
      const serverUrl = this.determineServerUrl();

      // Get gateway config
      const config = GATEWAY_CONFIG[gatewayType];

      // Construct the proper URL with namespace if needed
      const namespaceUrl = config.namespace
          ? `${serverUrl}/${config.namespace}`
          : serverUrl;

      console.log(`Socket.IO Connection Config for ${gatewayType}:`, {
        serverUrl: namespaceUrl,
        path: config.path,
        firebaseUid,
        connectionId: socketState.connectionId
      });

      // Create socket
      socketState.socket = io(namespaceUrl, {
        auth: {
          token,
          firebaseUid,
          connectionId: socketState.connectionId,
          clientInfo: this.getClientInfo()
        },
        path: config.path,
        timeout: 20000,
        reconnection: true,
        reconnectionAttempts: 0, // Handle reconnection manually for more control
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        randomizationFactor: 0.5,
        transports: ['websocket', 'polling'],
        extraHeaders: {
          'X-Client-Version': process.env.REACT_APP_VERSION || 'unknown',
          'X-Client-Type': 'web',
          'X-Connection-ID': socketState.connectionId,
          'X-Gateway-Type': gatewayType
        }
      });

      // Set up event listeners
      return new Promise<Socket | null>((resolve, reject) => {
        if (!socketState.socket) {
          const error = new Error(`Failed to create socket instance for ${gatewayType}`);
          socketState.lastConnectionError = error;
          socketState.connectionState = CONNECTION_STATES.ERROR;
          reject(error);
          return;
        }

        // Add connection timeout
        const connectionTimeout = setTimeout(() => {
          console.error(`${gatewayType} socket connection timeout`);
          if (socketState.socket) {
            const metrics = this.metrics.get(gatewayType);
            if (metrics) metrics.disconnections++;

            socketState.socket.disconnect();
          }
          socketState.lastConnectionError = new Error('Connection timeout');
          socketState.connectionState = CONNECTION_STATES.ERROR;
          resolve(null);
        }, 15000);

        socketState.socket.on('connect', () => {
          clearTimeout(connectionTimeout);

          console.log(`${gatewayType} Socket Connected Successfully:`, {
            id: socketState.socket?.id,
            firebaseUid,
            connectionId: socketState.connectionId
          });

          socketState.connectionState = CONNECTION_STATES.CONNECTED;
          socketState.connectedUserId = firebaseUid;
          socketState.reconnectAttempts = 0; // Reset counter on successful connection

          // Re-register all event handlers on the new socket
          this.reattachEventHandlers(gatewayType);

          // Restart heartbeat for connection monitoring
          this.startHeartbeat(gatewayType);

          // Send ready signal with slight delay to prevent race conditions
          setTimeout(() => {
            if (socketState.socket?.connected && !socketState.readySent) {
              socketState.socket?.emit('ready', {
                clientTime: new Date().toISOString(),
                firebaseUid,
                connectionId: socketState.connectionId,
                clientInfo: this.getClientInfo()
              });
              socketState.readySent = true;
            }
          }, 300);

          resolve(socketState.socket);
        });

        socketState.socket.on('connect_error', (error) => {
          clearTimeout(connectionTimeout);
          socketState.lastConnectionError = error;
          socketState.reconnectAttempts++;
          socketState.connectionState = CONNECTION_STATES.ERROR;

          console.error(`${gatewayType} Socket Connection Error:`, {
            message: error.message,
            attempt: socketState.reconnectAttempts,
            maxAttempts: this.maxReconnectAttempts
          });

          // If max attempts reached, reset the socket
          if (socketState.reconnectAttempts >= this.maxReconnectAttempts) {
            console.error(`Max reconnection attempts reached for ${gatewayType}, giving up`);
            socketState.socket?.disconnect();
            socketState.socket = null;
            socketState.connectedUserId = null;
          }

          resolve(null);
        });

        // Listen for reconnect events
        socketState.socket.on('reconnect', (attemptNumber) => {
          console.log(`${gatewayType} socket reconnected after ${attemptNumber} attempts`);
          socketState.connectionState = CONNECTION_STATES.CONNECTED;

          // Re-authenticate on reconnect if needed
          if (socketState.connectedUserId && socketState.socket) {
            socketState.readySent = false;

            setTimeout(() => {
              if (socketState.socket?.connected && !socketState.readySent) {
                socketState.socket.emit('ready', {
                  clientTime: new Date().toISOString(),
                  firebaseUid: socketState.connectedUserId,
                  reconnected: true,
                  connectionId: socketState.connectionId
                });
                socketState.readySent = true;
              }
            }, 300);
          }
        });

        // Monitor disconnections
        socketState.socket.on('disconnect', (reason) => {
          console.warn(`${gatewayType} socket disconnected: ${reason}`);
          socketState.readySent = false;
          socketState.connectionState = CONNECTION_STATES.DISCONNECTED;

          const metrics = this.metrics.get(gatewayType);
          if (metrics) metrics.disconnections++;

          // Log details about the disconnection
          console.log(`${gatewayType} disconnection details:`, {
            reason,
            wasConnected: socketState.connectedUserId !== null,
            firebaseUid: socketState.connectedUserId,
            socketId: socketState.socket?.id,
            connectionId: socketState.connectionId
          });

          if (['io server disconnect', 'transport close', 'ping timeout'].includes(reason)) {
            // Server-initiated disconnection, attempt reconnection
            this.attemptReconnect(gatewayType, 2000);
          }
        });

        socketState.socket.on('error', (error) => {
          console.error(`${gatewayType} socket error:`, error);
          socketState.connectionState = CONNECTION_STATES.ERROR;
        });

        // Handle explicit acknowledgments from server
        socketState.socket.on('connected', (data) => {
          console.log(`${gatewayType} server acknowledged connection:`, data);
        });

        socketState.socket.on('ready_ack', (data) => {
          console.log(`${gatewayType} server acknowledged ready state:`, data);
        });

        // Track message receipts for metrics
        socketState.socket.onAny(() => {
          const metrics = this.metrics.get(gatewayType);
          if (metrics) metrics.messagesReceived++;
        });
      });
    } catch (error) {
      console.error(`${gatewayType} WebSocket Connection Error:`, error);
      socketState.lastConnectionError = error instanceof Error ? error : new Error(String(error));
      socketState.connectionState = CONNECTION_STATES.ERROR;
      return null;
    }
  }

  // Get browser/device information for better debugging
  private getClientInfo(): Record<string, string> {
    const info: Record<string, string> = {
      userAgent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform
    };

    // Add connection type if available
    // Use feature detection to avoid TypeScript errors with navigator.connection
    const nav = navigator as any;
    if (nav.connection) {
      const conn = nav.connection;
      if (conn.effectiveType) info.networkType = conn.effectiveType;
      if (conn.type) info.connectionType = conn.type;
    }

    return info;
  }

  // Determine server URL based on environment
  private determineServerUrl(): string {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const isLocalhost = window.location.hostname === 'localhost';
    const isStaging = window.location.hostname.includes('dev.equilityhq.com');
    const isProduction = window.location.hostname.includes('app.equilityhq.com');

    let serverUrl = '';

    if (isDevelopment && isLocalhost) {
      serverUrl = `http://${window.location.hostname}:3000`;
    } else if (isStaging) {
      serverUrl = 'https://api-dev.equilityhq.com';
    } else if (isProduction) {
      serverUrl = 'https://api.equilityhq.com';
    } else {
      serverUrl = window.location.origin;
    }

    return serverUrl;
  }

  getSocket(gatewayType: string = GATEWAY_TYPES.RECONCILIATION): Socket | null {
    const socketState = this.sockets.get(gatewayType);
    return socketState ? socketState.socket : null;
  }

  isConnected(gatewayType: string = GATEWAY_TYPES.RECONCILIATION): boolean {
    const socketState = this.sockets.get(gatewayType);
    return socketState ?
        (socketState.connectionState === CONNECTION_STATES.CONNECTED && !!socketState.socket?.connected) :
        false;
  }

  getConnectionState(gatewayType: string = GATEWAY_TYPES.RECONCILIATION): string {
    const socketState = this.sockets.get(gatewayType);
    return socketState ? socketState.connectionState : CONNECTION_STATES.DISCONNECTED;
  }

  // Enhanced event handler registration with duplicate prevention
  on(event: string, handler: Function, gatewayType: string = GATEWAY_TYPES.RECONCILIATION): void {
    if (!event || typeof handler !== 'function') {
      console.warn('Invalid event or handler');
      return;
    }

    // Get handlers map for this gateway
    const gatewayHandlers = this.eventHandlers.get(gatewayType);
    if (!gatewayHandlers) {
      console.error(`Event handlers not found for gateway: ${gatewayType}`);
      return;
    }

    // Generate a unique ID for this handler
    const handlerId = this.getHandlerKey(handler);

    // Get the socket state
    const socketState = this.sockets.get(gatewayType);
    if (!socketState) {
      console.error(`Socket state not found for gateway: ${gatewayType}`);
      return;
    }

    // First, make sure we have a Map for this event type
    if (!gatewayHandlers.has(event)) {
      gatewayHandlers.set(event, new Map());
    }

    // Get the handlers map for this event
    const handlers = gatewayHandlers.get(event);
    if (!handlers) return;

    // Check if we already have this exact handler
    if (handlers.has(handlerId)) {
      return;
    }

    // Add handler to our tracking map
    handlers.set(handlerId, handler);

    // Attach to socket if connected
    if (socketState.socket && socketState.socket.connected) {
      // Make sure we don't already have this handler on the socket
      socketState.socket.off(event, handler as any);

      // Then add it
      socketState.socket.on(event, handler as any);

      // Track this registration
      socketState.registeredSocketEvents.add(`${event}-${handlerId}`);
    }
  }

  off(event: string, handler: Function, gatewayType: string = GATEWAY_TYPES.RECONCILIATION): void {
    if (!event || typeof handler !== 'function') return;

    // Get handlers map for this gateway
    const gatewayHandlers = this.eventHandlers.get(gatewayType);
    if (!gatewayHandlers) return;

    // Get socket state
    const socketState = this.sockets.get(gatewayType);
    if (!socketState) return;

    if (gatewayHandlers.has(event)) {
      const handlers = gatewayHandlers.get(event);
      if (handlers) {
        // Find and remove the handler by key
        const handlerKey = this.getHandlerKey(handler);
        handlers.delete(handlerKey);

        if (handlers.size === 0) {
          gatewayHandlers.delete(event);
        }
      }
    }

    if (socketState.socket) {
      socketState.socket.off(event, handler as any);

      // Remove from registered events
      const socketEventKey = `${event}-${this.getHandlerKey(handler)}`;
      socketState.registeredSocketEvents.delete(socketEventKey);
    }
  }

  // Reattach all event handlers to a new socket connection
  private reattachEventHandlers(gatewayType: string): void {
    const socketState = this.sockets.get(gatewayType);
    if (!socketState || !socketState.socket) return;

    const gatewayHandlers = this.eventHandlers.get(gatewayType);
    if (!gatewayHandlers) return;

    console.log(`Reattaching event handlers to new ${gatewayType} socket connection`);
    socketState.registeredSocketEvents.clear();
    let attachedCount = 0;

    // Reattach all registered handlers
    gatewayHandlers.forEach((handlers, event) => {
      console.debug(`Reattaching ${handlers.size} handlers for event: ${event}`);

      handlers.forEach((handler, key) => {
        // First remove to prevent duplicates
        socketState.socket?.off(event, handler as any);
        // Then add
        socketState.socket?.on(event, handler as any);

        // Track that we've registered this handler
        const socketEventKey = `${event}-${key}`;
        socketState.registeredSocketEvents.add(socketEventKey);
        attachedCount++;
      });
    });

    console.log(`Reattached ${attachedCount} total handlers across ${gatewayHandlers.size} events for ${gatewayType}`);
  }

  // Generate a unique key for a handler function
  private getHandlerKey(handler: Function): string {
    // First check if we've already assigned an ID to this handler
    if (this.handlerWeakMap.has(handler)) {
      return this.handlerWeakMap.get(handler)!;
    }

    // Generate a new unique ID for this handler
    const id = this.generateUniqueId();
    this.handlerWeakMap.set(handler, id);
    return id;
  }

  disconnect(gatewayType: string = GATEWAY_TYPES.RECONCILIATION): void {
    const socketState = this.sockets.get(gatewayType);
    if (!socketState) return;

    // Only disconnect if there are no active operations for this gateway
    const hasActiveOps = Array.from(this.activeOperations.values())
        .some(op => op.gatewayType === gatewayType);

    if (hasActiveOps) {
      console.log(`Cannot disconnect ${gatewayType} due to active operations`);
      return;
    }

    if (socketState.socket) {
      console.log(`Manually disconnecting ${gatewayType} socket`);

      const metrics = this.metrics.get(gatewayType);
      if (metrics) metrics.disconnections++;

      socketState.socket.disconnect();
      socketState.socket = null;
      socketState.connectedUserId = null;
      socketState.readySent = false;
      socketState.connectionState = CONNECTION_STATES.DISCONNECTED;
    }

    // Clear any pending reconnect timer
    if (socketState.reconnectTimer) {
      clearTimeout(socketState.reconnectTimer);
      socketState.reconnectTimer = null;
    }

    // Clear heartbeat timer
    if (socketState.heartbeatTimer) {
      clearInterval(socketState.heartbeatTimer);
      socketState.heartbeatTimer = null;
    }

    // Clear ping timeout timer
    if (socketState.pingTimeoutTimer) {
      clearTimeout(socketState.pingTimeoutTimer);
      socketState.pingTimeoutTimer = null;
    }
  }

  // Register an active operation to prevent disconnection
  registerActiveOperation(id: string, type: string, gatewayType: string = GATEWAY_TYPES.RECONCILIATION): void {
    if (!id || !type) {
      console.warn('Invalid operation registration attempt', { id, type, gatewayType });
      return;
    }

    this.activeOperations.set(id, {
      id,
      type,
      gatewayType,
      startTime: Date.now(),
      lastUpdate: Date.now()
    });

    console.log(`Registered active operation on ${gatewayType}: ${type} (${id})`);

    // Ensure we have an active connection for this operation
    if (!this.isConnected(gatewayType)) {
      console.log(`Ensuring ${gatewayType} connection for operation: ${type}`);
      this.connect(gatewayType).catch(err => {
        console.error(`Failed to connect to ${gatewayType} for operation ${id}:`, err);
      });
    }
  }

  // Unregister a completed operation
  unregisterActiveOperation(id: string): void {
    if (!id) return;

    if (this.activeOperations.has(id)) {
      const gatewayType = this.activeOperations.get(id)?.gatewayType || GATEWAY_TYPES.RECONCILIATION;
      this.activeOperations.delete(id);
      console.log(`Unregistered operation: ${id} from ${gatewayType}`);
    }
  }

  // Check if there are any active operations for a specific gateway
  hasActiveOperations(gatewayType: string = GATEWAY_TYPES.RECONCILIATION): boolean {
    return Array.from(this.activeOperations.values())
        .some(op => op.gatewayType === gatewayType);
  }

  // Get connection statistics
  getConnectionStats(gatewayType: string = GATEWAY_TYPES.RECONCILIATION): object {
    const socketState = this.sockets.get(gatewayType);
    const metrics = this.metrics.get(gatewayType);

    if (!socketState || !metrics) {
      return { error: `Gateway type ${gatewayType} not found` };
    }

    const gatewayHandlers = this.eventHandlers.get(gatewayType);

    return {
      ...metrics,
      connectionState: socketState.connectionState,
      connectedUserId: socketState.connectedUserId,
      activeOperations: this.countActiveOperationsForGateway(gatewayType),
      reconnectAttempts: socketState.reconnectAttempts,
      registeredEventTypes: gatewayHandlers ? gatewayHandlers.size : 0,
      totalRegisteredHandlers: gatewayHandlers ?
          Array.from(gatewayHandlers.values())
              .reduce((total, handlers) => total + handlers.size, 0) : 0,
      gatewayType
    };
  }

  // Count active operations for a specific gateway
  private countActiveOperationsForGateway(gatewayType: string): number {
    return Array.from(this.activeOperations.values())
        .filter(op => op.gatewayType === gatewayType)
        .length;
  }

  // Clean up stale operations
  cleanupStaleOperations(): void {
    const now = Date.now();
    const staleThreshold = 3600000; // 1 hour

    for (const [id, operation] of this.activeOperations.entries()) {
      if (now - operation.lastUpdate > staleThreshold) {
        console.warn(`Operation ${id} on ${operation.gatewayType} appears stale, removing from tracking`);
        this.activeOperations.delete(id);
      }
    }
  }

  // Update operation with latest activity
  updateOperationActivity(id: string): void {
    if (this.activeOperations.has(id)) {
      const operation = this.activeOperations.get(id)!;
      operation.lastUpdate = Date.now();
      this.activeOperations.set(id, operation);
    }
  }

  // Implement a controlled reconnect method with exponential backoff
  attemptReconnect(gatewayType: string, immediateDelay?: number): void {
    const socketState = this.sockets.get(gatewayType);
    if (!socketState) return;

    this.cleanupStaleOperations();

    // Don't schedule multiple reconnects
    if (socketState.reconnectTimer || socketState.connectionState === CONNECTION_STATES.CONNECTING) {
      return;
    }

    // Calculate backoff with jitter based on reconnect attempts
    // Cap at 30 seconds maximum delay
    const baseDelay = immediateDelay !== undefined ?
        immediateDelay :
        Math.min(30000, Math.pow(2, Math.min(4, socketState.reconnectAttempts)) * 1000);

    // Add jitter to prevent thundering herd problem (±30%)
    const jitter = baseDelay * (0.7 + Math.random() * 0.6);
    const delay = Math.round(baseDelay + jitter);

    console.log(`Scheduling ${gatewayType} reconnect in ${delay}ms (attempt ${socketState.reconnectAttempts + 1})`);

    socketState.reconnectTimer = setTimeout(async () => {
      socketState.reconnectTimer = null;

      // Only attempt to connect if we're still disconnected
      if (socketState.connectionState !== CONNECTION_STATES.CONNECTED) {
        console.log(`Attempting scheduled reconnection to ${gatewayType}`);
        await this.connect(gatewayType);
      }
    }, delay);
  }

  // Clean up all resources for a specific gateway
  destroyGateway(gatewayType: string): void {
    const socketState = this.sockets.get(gatewayType);
    if (!socketState) return;

    // Only destroy if no active operations
    if (this.hasActiveOperations(gatewayType)) {
      console.log(`Cannot destroy ${gatewayType} gateway due to active operations`);
      return;
    }

    // Clear all timers
    if (socketState.heartbeatTimer) {
      clearInterval(socketState.heartbeatTimer);
      socketState.heartbeatTimer = null;
    }

    if (socketState.reconnectTimer) {
      clearTimeout(socketState.reconnectTimer);
      socketState.reconnectTimer = null;
    }

    if (socketState.pingTimeoutTimer) {
      clearTimeout(socketState.pingTimeoutTimer);
      socketState.pingTimeoutTimer = null;
    }

    // Disconnect socket
    if (socketState.socket) {
      socketState.socket.disconnect();
      socketState.socket = null;
    }

    // Reset state
    socketState.connectionState = CONNECTION_STATES.DISCONNECTED;
    socketState.connectedUserId = null;
    socketState.readySent = false;
    socketState.registeredSocketEvents.clear();

    // Remove event handlers for this gateway
    this.eventHandlers.set(gatewayType, new Map());

    console.log(`${gatewayType} gateway destroyed and resources cleaned up`);
  }

  // Clean up all resources across all gateways
  destroy(): void {
    // Remove event listeners
    if (typeof window !== 'undefined') {
      window.removeEventListener('online', this.handleOnline);
      window.removeEventListener('offline', this.handleOffline);
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }

    // Cleanup each gateway
    for (const gatewayType of Object.values(GATEWAY_TYPES)) {
      this.destroyGateway(gatewayType);
    }

    // Clear all maps
    this.activeOperations.clear();
    this.eventHandlers.clear();
    this.metrics.clear();

    console.log('SocketManager fully destroyed and all resources cleaned up');
  }
}

// Export simplified methods for components to use
export const connectToWebSocket = async (
    gatewayType: string = GATEWAY_TYPES.RECONCILIATION
): Promise<Socket | null> => {
  return await SocketManager.getInstance().connect(gatewayType);
};

export const disconnectFromWebSocket = (
    gatewayType: string = GATEWAY_TYPES.RECONCILIATION
): void => {
  SocketManager.getInstance().disconnect(gatewayType);
};

export const isSocketConnected = (
    gatewayType: string = GATEWAY_TYPES.RECONCILIATION
): boolean => {
  return SocketManager.getInstance().isConnected(gatewayType);
};

export const getSocketConnectionState = (
    gatewayType: string = GATEWAY_TYPES.RECONCILIATION
): string => {
  return SocketManager.getInstance().getConnectionState(gatewayType);
};

export const getSocket = (
    gatewayType: string = GATEWAY_TYPES.RECONCILIATION
): Socket | null => {
  return SocketManager.getInstance().getSocket(gatewayType);
};

export const getConnectionStats = (
    gatewayType: string = GATEWAY_TYPES.RECONCILIATION
): object => {
  return SocketManager.getInstance().getConnectionStats(gatewayType);
};

// Event handler management
export const onSocketEvent = (
    event: string,
    handler: Function,
    gatewayType: string = GATEWAY_TYPES.RECONCILIATION
): void => {
  if (!event || typeof handler !== 'function') {
    console.warn('Invalid event or handler provided to onSocketEvent', { event, gatewayType });
    return;
  }

  // Optimized approach: just let the manager handle duplicates internally
  const manager = SocketManager.getInstance();
  manager.on(event, handler, gatewayType);
};

export const offSocketEvent = (
    event: string,
    handler: Function,
    gatewayType: string = GATEWAY_TYPES.RECONCILIATION
): void => {
  if (!event || typeof handler !== 'function') return;
  SocketManager.getInstance().off(event, handler, gatewayType);
};

// Add operation tracking for critical processes
export const registerOperation = (
    id: string,
    type: string,
    gatewayType: string = GATEWAY_TYPES.RECONCILIATION
): void => {
  if (!id || !type) {
    console.warn('Invalid operation registration attempt', { id, type, gatewayType });
    return;
  }
  SocketManager.getInstance().registerActiveOperation(id, type, gatewayType);
};

export const unregisterOperation = (id: string): void => {
  if (!id) return;
  SocketManager.getInstance().unregisterActiveOperation(id);
};

export const updateOperation = (id: string): void => {
  if (!id) return;
  SocketManager.getInstance().updateOperationActivity(id);
};

export const hasActiveOperations = (
    gatewayType: string = GATEWAY_TYPES.RECONCILIATION
): boolean => {
  return SocketManager.getInstance().hasActiveOperations(gatewayType);
};

// Utility to ensure socket is connected before an operation
export const ensureSocketConnected = async (
    gatewayType: string = GATEWAY_TYPES.RECONCILIATION
): Promise<boolean> => {
  try {
    if (isSocketConnected(gatewayType)) {
      return true;
    }

    console.log(`Ensuring ${gatewayType} socket connection...`);
    const socket = await connectToWebSocket(gatewayType);
    const connected = !!socket?.connected;

    if (!connected) {
      console.warn(`Failed to establish ${gatewayType} socket connection`);
    }

    return connected;
  } catch (error) {
    console.error(`Error in ensureSocketConnected for ${gatewayType}:`, error);
    return false;
  }
};

// Cleanup utility for component unmounts
export const cleanupWebSocket = (
    gatewayType?: string
): void => {
  const manager = SocketManager.getInstance();

  if (gatewayType) {
    // Only clean up specified gateway if there are no active operations
    if (!manager.hasActiveOperations(gatewayType)) {
      manager.destroyGateway(gatewayType);
    }
  } else {
    // Check if any gateway has active operations
    const hasAnyActiveOps = Object.values(GATEWAY_TYPES).some(type =>
        manager.hasActiveOperations(type)
    );

    // Only destroy the entire manager if no active operations anywhere
    if (!hasAnyActiveOps) {
      manager.destroy();
    }
  }
};